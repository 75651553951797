

<template>
  <div class="home">
    <AssemblyFlex/>
  </div>
</template>

<script>
import AssemblyFlex from '@/components/AssemblyFlex.vue'

export default {
  name: 'HomeView',
  components: {
    AssemblyFlex
    
}
}
</script>
